import React from "react";

import MoveEasy from "../../assets/moveeasy-logo.png";
import McCollisters from "../../assets/McCollisters-logo.png";

export default function Header(props) {
    return (
        <header id="header">
            <div className="app-heading">
                <img id="primary-logo" src="https://res.cloudinary.com/dq27r8cov/image/upload/v1616098696/McCollister%27s/moveeasy-logo.png" />
                <div className="app-sub-heading">
                    {/* <div id="line-1">
                        <p>Managed by</p>
                        <img id="logo-2" src={} />
                    </div> */}
                    {/* <div id="line-2">
                        <p>Powered by</p>
                        <img id="logo-3" src={McCollisters} />
                    </div> */}
                </div>
            </div>

        </header>
    )
}