import React, { useState, useEffect } from "react";
import { withRouter, NavLink, useHistory } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect, useDispatch } from 'react-redux';
import { useParams } from "react-router";
import { library } from '@fortawesome/fontawesome-svg-core'
// import { faGarageCar } from '@fortawesome/pro-duotone-svg-icons'


// Add font awesome icons to icon library
// library.add([faGarageCar]);

function QuoteDetail(props) {

    let quote = props.quote;

    const transportTypeValue = () => {
        if (quote.transportType === 'ENCLOSED') {
            return (
                <span>Enclosed</span>
            )
        }
        return <span>Open</span>
    }

    return (
        <div className="quote-detail view-content-container">

            <p>Please find your quote below.  Quotes are valid for 30 days from the date they were created.  From here, you can book your order or please note and save your confirmation code if you did not choose to have the quote emailed to you.  You can always return later to enter your code and book your transport.</p>

            <div className="view-header">
                <h3>Confirmation Code: {quote.customerCode}</h3>
            </div>

            <div className="quote-detail-container">

                <div className="quote-detail-info">All of the pricing options below include:
                    <ul>
                        <li>Door-to-door service for both pick up and delivery.</li>
                        <li>Guaranteed on time delivery (rental car provided in event of driver caused delay on delivery).</li>
                        <li>Insurance coverage included.</li>
                        <li>All taxes and fees are included in this price quote.</li>
                        {quote.transportType === 'ENCLOSED' && <li className="color-red">Please note that for enclosed transport, in order to meet your required pick up date, we reserve the right to use a flatbed to pick up the vehicle and bring it to a secure terminal location while awaiting arrival of the enclosed transport truck.</li>}
                    </ul>
                </div>


                <div className="quote-detail-vehicles">
                    <div className="table table-header quote-detail-table">
                        <div className="table-item">Make</div>
                        <div className="table-item">Model</div>
                        <div className="table-item">Class</div>
                        <div className="table-item">Operable</div>

                        {props.serviceLevels.map(serviceLevel => {
                            return (
                                <div key={serviceLevel.name} className="table-item">{serviceLevel.name}</div>
                            )
                        })
                        }

                    </div>

                    {quote.vehicleQuotes.map((vehicle, i) => {

                        return (
                            <div key={vehicle.model} className="table table-row quote-detail-table">
                                <div className="table-item">{vehicle.make}</div>
                                <div className="table-item">{vehicle.model}</div>
                                <div className="table-item">{vehicle.model === "Other Pre-1975 Classic" || vehicle.model === "Other - Pickup" ? "" : vehicle.pricingClass}</div>
                                <div className="table-item">{vehicle.operable}</div>

                                {props.serviceLevels.map(serviceLevel => {
                                    // Find the totalPortal quote for the vehicle at this serviceLevel
                                    let foundQuote = vehicle.calculatedQuotes.find(calcQuote => {
                                        return serviceLevel.value === calcQuote.days;
                                    });

                                    return (
                                        <div key={foundQuote.totalPortal} className="table-item">${foundQuote.totalPortal}</div>
                                    )
                                })
                                }

                            </div>
                        );
                    })}

                    <div className="table quote-detail-table">
                        <div className="table-item"></div>
                        <div className="table-item"></div>
                        <div className="table-item"></div>
                        <div className="table-item"><strong>Total:</strong></div>

                        {props.serviceLevels.map(serviceLevel => {
                            let totalPricingArray = quote.totalPricing;
                            return (
                                <div key={totalPricingArray[serviceLevel.value].totalPortal} className="table-item total-item">${totalPricingArray[serviceLevel.value].totalPortal}</div>
                            )
                        })
                        }

                    </div>
                </div>

                <div className="pickup-delivery-details">

                    <div className="row">
                        <span className="row-label">Pickup:</span>
                        <span>{quote.pickup}</span>
                    </div>

                    <div className="row">
                        <span className="row-label">Delivery:</span>
                        <span>{quote.delivery}</span>
                    </div>

                    {quote.customerFullName &&
                        <div className="row">
                            <span className="row-label">Your Name:</span>
                            <span>{quote.customerFullName}</span>
                        </div>
                    }

                    {quote.customerEmail &&
                        <div className="row">
                            <span className="row-label">Your Email:</span>
                            <span>{quote.customerEmail}</span>
                        </div>
                    }

                    <div className="row">
                        <span className="row-label">Miles:</span>
                        <span>{quote.miles}</span>
                    </div>

                    <div className="row">
                        <span className="row-label">Transport Type:</span>
                        {transportTypeValue()}
                    </div>

                    {quote.transitTime &&
                        <div className="row">
                            <span className="row-label">Transit Time:</span>
                            <span>{quote.transitTime[0]} - {quote.transitTime[1]} Days</span>
                        </div>}



                    <div className="action-row">

                        {
                            props.quote &&
                            <div className="button-container">
                                <NavLink to="/order"><button className="form-submit">Book Order</button></NavLink>
                            </div>
                        }

                    </div>

                </div>


            </div>

        </div>
    )



}


export default QuoteDetail;